<template>
  <div class="checkout-container">
    <div class="edit-div">
      <p
        class="amount"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
      >
        {{ this.$t("payment.toPay") + " : " + currentPrice / 100 }}€
      </p>
      <div v-if="payment.card">
        <input
          type="radio"
          id="use-saved"
          value="1"
          v-model.number="useSavedCard"
        />
        <label for="use-saved" v-if="payment.card">{{
          $t("payment.useSavedCard", { lastDigits: payment.card.last4 })
        }}</label>
        <br />
        <br />
        <input
          type="radio"
          id="use-new"
          value="0"
          v-model.number="useSavedCard"
        />
        <label for="use-new" v-if="payment.card">{{
          $t("payment.newCard")
        }}</label>
        <br v-if="useSavedCard == 0" />
        <br v-if="useSavedCard == 0" />
      </div>
      <div ref="cardElementContainer" v-show="useSavedCard == 0"></div>
      <br v-if="useSavedCard == 0" />
      <br v-if="useSavedCard == 0" />
      <input
        type="checkbox"
        id="save-card"
        v-model="saveCard"
        :disabled="useSavedCard === 1"
        v-show="useSavedCard == 0"
      />
      <label for="save-card" v-show="useSavedCard == 0">{{
        $t("payment.saveCard")
      }}</label>
      <br />
      <br />
      <p>{{ $t("payment.billingAddress") }}</p>
      <input
        id="company"
        type="text"
        :placeholder="$t('label.company')"
        v-model="companyName"
      />
      <input
        id="tvaNumber"
        type="text"
        :placeholder="$t('label.vatNumber')"
        v-model="vatNumber"
        v-if="this.$store.state.tourAgencyId !== ''"
      />
      <br />
      <br />
      <input
        id="firstName"
        type="text"
        :placeholder="$t('label.surname')"
        v-model="firstName"
        required
      />
      <input
        id="lastName"
        type="text"
        :placeholder="$t('label.name')"
        v-model="lastName"
        required
      />
      <br />
      <br />

      <input
        id="address1"
        type="text"
        :placeholder="
          $t('label.travelForm.adress') + ' ' + $t('label.line', { number: 1 })
        "
        v-model="address1"
        required
      />
      <CountryList></CountryList>
      <br />
      <br />

      <input
        id="address2"
        type="text"
        :placeholder="
          $t('label.travelForm.adress') + ' ' + $t('label.line', { number: 2 })
        "
        v-model="address2"
      />
      <br />
      <br />
      <input
        id="zipcode"
        type="text"
        :placeholder="$t('label.zipCode')"
        v-model="zipcode"
        required
      />
      <input
        id="city"
        type="text"
        :placeholder="$t('label.travelForm.city')"
        v-model="city"
        required
      />
      <br />
      <br />

      <input id="coupon" type="text" v-model="couponCode" />
      <button id="coupon-button" @click="updatePrice()">
        {{ $t("buttons.submitVoucher") }}
      </button>
      <br />
      <br />
      <input type="checkbox" id="save-billing" v-model="saveBilling" />
      <label for="save-billing">{{ $t("payment.saveBilling") }}</label>
      <br />
      <br />
      <button
        id="checkout-button"
        @click="startCheckout()"
        :disabled="isButtonDisabled"
      >
        {{ $t("payment.submit") }}
      </button>
      <!-- placeholder -->
      <!--<p class="error-message" v-if="errorContent != ''">{{ errorContent }}</p>-->
      <!--<br v-if="errorContent != ''" />-->
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import CountryList from "@/components/CountryList";

export default {
  name: "Checkout",
  components: { CountryList },
  mounted() {
    let script = document.createElement("script");
    script.onload = this.setupStripe;
    script.setAttribute("src", "https://js.stripe.com/v3/");
    script.async = true;
    document.head.appendChild(script);
  },
  props: {
    travelData: {
      type: Object,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPrice: this.price,
      currentCode: "",
      isLoading: false,
      firstName: this.payment.billing.firstName || "",
      lastName: this.payment.billing.lastName || "",
      address1: this.payment.billing.address1 || "",
      address2: this.payment.billing.address2 || "",
      zipcode: this.payment.billing.zipcode || "",
      city: this.payment.billing.city || "",
      couponCode: "",
      companyName: this.payment.billing.company || "",
      useSavedCard: this.payment.card ? 1 : 0,
      saveCard: false,
      saveBilling: false,
      vatNumber: this.payment.billing.vatNumber || "",
    };
  },
  computed: {
    isButtonDisabled: function () {
      return (
        this.isLoading ||
        this.firstName == "" ||
        this.lastName == "" ||
        this.address1 == "" ||
        this.zipcode == "" ||
        this.city == ""
      );
    },
  },
  methods: {
    async setupStripe() {
      if (window.Stripe === undefined) {
        alert(this.$t("payment.loadingError"));
      } else {
        const stripe = window.Stripe(
          (await HandleApi.getStripePublishableKey()).data.publishableKey
        );
        //"pk_test_sz9kiMTA3CLOVlHcHn8GSZ9c00qC6uwwEP"
        //"pk_live_3z2T7jXyHN8s8aFd1634j13200Pj8nAzMz"
        this.stripe = stripe;
        const elements = stripe.elements();
        const cardEl = document.createElement("card-element");
        this.cardElement = elements.create("card");
        this.cardElement.mount(cardEl);
        this.$refs.cardElementContainer.appendChild(cardEl);
      }
    },
    startCheckout() {
      this.$store.dispatch("load", this.$t("loadMessage.checkout"));
      this.isLoading = true;
      if (this.useSavedCard) {
        this.requestPayment(null, null)
          .then(this.processFromServer)
          .catch((err) => {
            this.$router.push("/");
            this.$store.dispatch("unload");
            this.$store.dispatch(
              "push_error_message",
              err.response.data || err
            );
          });
      } else {
        this.stripe
          .createPaymentMethod({
            type: "card",
            card: this.cardElement,
            //billing_details: { name: cardholderName.value }
          })
          .then((result) => {
            if (result.error) {
              this.isLoading = false;
              this.$store.dispatch("unload");
              this.$store.dispatch(
                "push_error_message",
                this.$t("payment.incorrectCard")
              );
            } else {
              let travel = this.requestPayment(
                null,
                result.paymentMethod.id
              ).then(this.processFromServer);
              travel.catch((err) => {
                this.$router.push("/");
                this.$store.dispatch("unload");
                this.$store.dispatch(
                  "push_error_message",
                  err.response.data || err
                );
              });
            }
          })
          .catch((err) => {
            window.console.log(err);
            this.$store.dispatch("unload");
            this.isLoading = false;
            this.$store.dispatch(
              "push_error_message",
              this.$t("payment.unidentified")
            );
          });
      }
    },
    requestPayment(paymentIntent, paymentMethod) {
      let data = this.travelData;
      data.code = this.currentCode;
      data.userId = this.$store.getters.userId;
      data.firstName = this.firstName;
      data.lastName = this.lastName;
      data.address1 = this.address1;
      data.address2 = this.address2;
      data.zipcode = this.zipcode;
      data.city = this.city;
      data.company = this.companyName;
      data.invoice_country = this.$store.state.countrySelector;
      data.vatNumber = this.vatNumber;
      if (paymentIntent) {
        data.paymentIntent = paymentIntent;
      }
      if (paymentMethod) {
        data.paymentMethod = paymentMethod;
      }
      data.savePaymentMethod = this.saveCard && !this.useSavedCard;
      data.saveBilling = this.saveBilling;
      this.$store.dispatch("unload");
      this.$store.dispatch("load", this.$t("loadMessage.createTravel"));
      return HandleApi.postTravel(data);
    },
    processFromServer(res) {
      if (res.data.success) {
        this.$store.dispatch("remove_affiliation");
        this.$store.dispatch("unload");
        this.$store.dispatch(
          "push_notification_message",
          this.$t("notification.created", {
            object: this.$t("notification.travel"),
          })
        );
        this.$router.push(
          "/travel/" + res.data.travelId + "?roadmapUpload=true"
        );
      } else if (res.data.requires_action) {
        this.stripe
          .handleCardAction(res.data.clientSecret)
          .then(this.processFromStripe);
      } else if (res.data.error) {
        this.isLoading = false;
        this.$store.dispatch("unload");
        alert(this.$t("payment.server"));
      } else {
        this.isLoading = false;
        this.$store.dispatch("unload");
        alert(this.$t("payment.unexpected"));
      }
    },
    processFromStripe(res) {
      if (res.paymentIntent) {
        let travel = this.requestPayment(res.paymentIntent.id).then(
          this.processFromServer
        );
        travel.catch((err) => {
          this.$router.push("/");
          this.$store.dispatch("unload");
          this.$store.dispatch("push_error_message", err.response.data || err);
        });
      } else {
        this.isLoading = false;
        this.$store.dispatch("unload");
        this.$store.dispatch(
          "push_error_message",
          this.$t("payment.unidentified")
        );
      }
    },
    updatePrice() {
      let data = this.travelData;
      data.code = this.couponCode;
      HandleApi.checkAndGetPrice(data)
        .then((res) => {
          this.currentPrice = res.data.price;
          this.currentCode = data.code;
        })
        .catch((err) => {
          this.$store.dispatch("push_error_message", err);
        });
    },
  },
};
</script>

<style scoped>
input {
  width: 200px;
  margin-bottom: 5px;
}

.checkout-container {
  text-align: center;
}

.edit-div {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
}

.amount {
  text-align: right;
  background-color: #00909f;
  color: white;
  font-weight: bold;
  padding: 10px;
}

#coupon-button {
  margin-left: 10px;
}

#checkout-button {
  margin-left: 10px;
}
</style>
